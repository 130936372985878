// On mobile devices, show the wizard CTA stripe as close to the form as possible
// On larger screens, show it in a consistent position at the bottom of the wizard

.wizard-main {
  flex-shrink: 0;
}

.wizard-cta {
  background-color: $white;
}

@include media-breakpoint-up(sm) {
  body {
    background-color: $qualdesk-light-blue;
  }
  
  .wizard {
    border-radius: 0.25em;
    border: 1px solid $gray-300;
    overflow: hidden;

    .wizard-main {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
    }

    .wizard-cta {
      background-color: $light;
      border-top: 1px solid $border-color;
    }
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.loading-spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}