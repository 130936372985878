$enable-responsive-font-sizes: true;

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$qualdesk-blue: #4A90E2;
$qualdesk-light-blue: #EDF4FC;
$qualdesk-mid-blue: #E4EEFB;
$qualdesk-green: #50E3C2;
$qualdesk-dark-green: #215D50;

$theme-colors: (
  "primary": $qualdesk-blue,
  "qualdesk-green": $qualdesk-green,
  "qualdesk-dark-green": $qualdesk-dark-green,
  "qualdesk-light-blue": $qualdesk-light-blue,
  "qualdesk-mid-blue": $qualdesk-mid-blue,
  "qualdesk-mid-gray": $gray-400,
);

@import "../../node_modules/bootstrap/scss/bootstrap";
