.steps {
  .step {
    position: relative;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }

  .step-number {
    z-index: 10;
  }

  .step-label {
    font-size: small;
    line-height: normal;
  }

  > :not(:first-child)::before {
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    left: -50%;
    top: 14px;
    background-color: $gray-600;
  }

  > .active:not(:first-child)::before {
    background-color: $qualdesk-blue;
  }

  > .done:not(:first-child)::before {
    background-color: $qualdesk-green;
  }
}
